$base-font-size: 16; // Base font-size in pixels.

@use "sass:math";
@use "../abstract/typography.scss" as t;
@use "../abstract/colors.scss" as c;

@function pxtorem($px) {
  @return #{math.div($px, $base-font-size)}rem;
}

.large-button {
  cursor: pointer;
  width: fit-content;
  height: pxtorem(50);
  padding: pxtorem(15) pxtorem(20) pxtorem(15) pxtorem(20);
  border-radius: pxtorem(10);
  @extend .title4;
  box-shadow: none;
  text-transform: inherit;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: pxtorem(10);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.medium-button {
  cursor: pointer;
  width: fit-content;
  height: pxtorem(40);
  padding: pxtorem(10) pxtorem(15) pxtorem(10) pxtorem(15);
  border-radius: pxtorem(10);
  @extend .title4;
  box-shadow: none;
  text-transform: inherit;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: pxtorem(5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small-button {
  width: fit-content;
  cursor: pointer;
  height: pxtorem(30);
  padding: pxtorem(5) pxtorem(8) pxtorem(5) pxtorem(8);
  border-radius: pxtorem(8);
  @extend .caption;
  box-shadow: none;
  text-transform: inherit;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: pxtorem(5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-button {
  min-width: max-content;
  cursor: pointer;
  height: pxtorem(30);
  padding: 0px;
  @extend .caption;
  box-shadow: none;
  text-transform: inherit;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: pxtorem(5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-button {
  min-width: max-content;
  cursor: pointer;
  height: pxtorem(30);
  padding: pxtorem(5) pxtorem(10) pxtorem(5) pxtorem(10);
  border-radius: pxtorem(15);
  @extend .caption;
  box-shadow: none;
  text-transform: inherit;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: pxtorem(5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.primary-button {
  background-color: c.$yellow-zelt;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.primary-button:not(:disabled):hover {
  background-color: c.$yellow-hover;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.primary-button:target {
  background-color: c.$yellow-press;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.primary-button:disabled {
  cursor: not-allowed;
  background-color: #FFDB99;
}

.secondary-button {
  background-color: c.$background;
  color: c.$dark-grey;
  fill: c.$dark-grey;
}

.secondary-button:not(:disabled):hover {
  background-color: c.$grey-hover;
  color: c.$dark-grey;
  fill: c.$dark-grey;
}

.secondary-button:target {
  background-color: c.$grey-press;
  color: c.$dark-grey;
  fill: c.$dark-grey;
}

.secondary-button:disabled {
  cursor: default;
  opacity: 50%;
}

.light-button {
  background-color: c.$white;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.light-button:not(:disabled):hover {
  background-color: c.$grey-hover;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.light-button:target {
  background-color: c.$grey-press;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.light-button:disabled {
  cursor: default;
  opacity: 50%;
}

.danger-button {
  background-color: c.$red-dark;
  color: c.$white;
  fill: c.$white;
}

.danger-button:not(:disabled):hover {
  background-color: c.$red-hover;
  color: c.$white;
  fill: c.$white;
}

.danger-button:target {
  background-color: c.$red-dark;
  color: c.$white;
  fill: c.$white;
}

.danger-button:disabled {
  cursor: default;
  opacity: 50%;
}

.dashed-button {
  @extend .caption;
  background-color: c.$white;
  color: c.$dark-grey;
  fill: c.$dark-grey;
  border: 1px dashed c.$grey;
}

.dashed-button:active {
  @extend .caption;
  background-color: c.$white;
  color: c.$dark-grey;
  fill: c.$dark-grey;
  border: 1px dashed c.$grey;
}

.dashed-button:focus {
  @extend .caption;
  background-color: c.$white;
  color: c.$dark-grey;
  fill: c.$dark-grey;
  border: 1px dashed c.$grey;
}

.dashed-button:after {
  @extend .caption;
  background-color: c.$white;
  color: c.$dark-grey;
  fill: c.$dark-grey;
  border: 1px dashed c.$grey;
}

.dashed-button::before {
  @extend .caption;
  background-color: c.$white;
  color: c.$dark-grey;
  fill: c.$dark-grey;
  border: 1px dashed c.$grey;
}

.dashed-button:not(:disabled):hover {
  @extend .caption;
  background-color: c.$background-light;
  color: c.$dark-grey;
  fill: c.$dark-grey;
  border: 1px dashed c.$grey;
}

.dashed-button:target {
  @extend .caption;
  background-color: c.$background;
  color: c.$dark-grey;
  fill: c.$dark-grey;
  border: 1px dashed c.$grey;
}

.dashed-button:disabled {
  @extend .caption;
  cursor: default;
  opacity: 50%;
}

.fullWidth-button {
  width: 100%;
}

.button-loader-spin {
  animation: spin 1s linear infinite;
  stroke: c.$grey;
  width: pxtorem(20);
  height: pxtorem(20);
}

.tooltip-button {
  background-color: transparent;
  color: c.$grey;
  fill: c.$grey;
}

.tooltip-button:not(:disabled):hover {
  background-color: transparent;
  color: c.$grey-hover;
  fill: c.$grey-hover;
}

.tooltip-button:target {
  background-color: transparent;
  color: c.$grey-press;
  fill: c.$grey-press;
}

.tooltip-button:disabled {
  cursor: default;
  opacity: 50%;
}

.text-button {
  background-color: transparent;
  color: c.$dark-grey;
  fill: c.$dark-grey;
}

.text-button:not(:disabled):hover {
  background-color: transparent;
  color: c.$brown;
  fill: c.$brown;
}

.text-button:target {
  background-color: transparent;
  color: c.$grey;
  fill: c.$grey;
}

.text-button:disabled {
  cursor: default;
  opacity: 50%;
}

.textUnderline-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  color: c.$dark-grey;
  fill: c.$dark-grey;
  text-decoration: underline;
}

.textUnderline-button:not(:disabled):hover {
  background-color: transparent;
  color: c.$brown;
  fill: c.$brown;
  text-decoration: underline;
}

.textUnderline-button:target {
  background-color: transparent;
  color: c.$grey;
  fill: c.$grey;
  text-decoration: underline;
}

.textUnderline-button:disabled {
  cursor: default;
  opacity: 50%;
}

.active-button {
  background-color: c.$dark-grey;
  color: c.$background;
  fill: c.$background;
}

.active-button:not(:disabled):hover {
  background-color: c.$grey;
  color: c.$background;
  fill: c.$background;
}

.active-button:target {
  background-color: c.$grey-press;
  color: c.$background;
  fill: c.$background;
}

.active-button:disabled {
  cursor: default;
  opacity: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}